<template>
  <div>
    <!-- <div>
      <van-nav-bar title="资讯详情" />
    </div> -->
    <!-- 内容 -->
    <div class="essay">
      <!-- <div class="essayTitle">{{ list.Title }}</div>
      <div class="rikind">{{ list.AKName }}</div>
      <div v-if="list.Poster"
        class="essayImg">
        <img :src="list.PosterUrl"
          alt="" />
      </div>
      <div class="essayContent"
        v-html="list.Content"></div>
      <div class="video"
        v-if="list.Video">
        <video style="width: 100%"
          :src="list.Video"
          controls="controls">
          您的浏览器不支持 video 标签。
        </video>
      </div> -->
      <!-- <van-button v-if="list.AllowSignup == 1"
        class="button"
        @click="Activity"
        round
        type="info">活动申请</van-button> -->
      <div style="margin-bottom: 100px">
        <!-- <img :src="details.PosterUrl"
          style="width:100%;height:290px;" /> -->
        <van-swipe :autoplay="3000" @change="onChange">
          <van-swipe-item
            v-for="item in details.Imgs.split(',')"
            :key="item + 'aa'"
          >
            <img :src="item" style="width: 100%; height: 290px" />
          </van-swipe-item>
        </van-swipe>
        <!-- <van-divider content-position="left">基本信息</van-divider> -->
        <van-divider />
        <div>
          <div style="width: 95%; margin: auto">
            <!-- <div class="biaoti">
              <span class="title">{{details.Title}}</span>
              <span class="money">{{details.StatusDesc}} </span>
            </div> -->
            <div style="display: flex; justify-content: space-between">
              <div style="color: #31d984; margin: 5px 0 10px; font-size: 18px">
                <!-- <span>{{details.OrgName}}</span> | -->
                <span>{{ details.Name }}</span>
              </div>
              <div>
                <span class="shopType">{{ details.GradeDesc }}</span>
              </div>
            </div>

            <div class="time">
              <van-cell style="padding: 0" color="#c8cad8">
                <template #title>
                  <span class="custom-title time" style="font-size:16px;text">{{
                    details.TouchTel
                  }}</span>
                </template>
                <template #right-icon>
                  <van-button
                    size="small"
                    style="border-radius: 5px; width: 22%"
                    type="info"
                    @click="addHotel(details)"
                    >编 辑</van-button
                  >
                </template>
              </van-cell>
            </div>
            <div class="time">
              <van-cell style="padding: 0">
                <template #title>
                  <span class="custom-title time" style="font-size: 16px"
                    >房间数：{{ details.RoomNum }}；床位数：{{
                      details.BedNum
                    }}</span
                  >
                </template>
              </van-cell>
            </div>
            <div class="time">
              <van-cell style="padding: 0">
                <template #title>
                  <span class="custom-title time" style="font-size: 16px">{{
                    details.Address
                  }}</span>
                </template>
              </van-cell>
            </div>
          </div>
        </div>
        <!-- <div class="mapImg"
          style="height: 70px;">
          <div style="padding:10px 5%;display: flex;justify-content: space-between;">
            <div style="display: flex;width: 70%;justify-content: flex-start;">
              <img src="http://xczxapi.zhsq.cloud//upload/images/2022/6/1e7faeee-544.png"
                alt=""
                class="addressImage"
                style="width:20px;">
              <div class="address"
                style="margin-left:10px;">{{details.Address}}</div>
            </div>

            <div style="float: right;color:#31D984;padding-top: 5px;"
              @click="gotominiProgram(details)">
              地图
              <van-icon name="arrow"
                color="#999999" />
            </div>
          </div>

        </div> -->
        <div>
          <van-divider />
          <div style="width: 95%; margin: auto">
            <div class="time">
              <van-cell style="padding: 0">
                <template #title>
                  <span class="custom-title time" style="font-size: 16px"
                    >管理员：{{ details.Manager }}</span
                  >
                </template>
                <template #right-icon>
                  <!-- <van-button size="small"
                    style="border-radius:5px"
                    @click="managerClick(details)">设置管理员</van-button> -->
                </template>
              </van-cell>
            </div>
            <div class="time">
              <van-cell style="padding: 0">
                <template #title>
                  <span class="custom-title time" style="font-size: 16px">{{
                    details.Mobile
                  }}</span>
                </template>
              </van-cell>
            </div>
          </div>
          <van-divider />
        </div>
        <div>
          <div style="width: 95%; margin: auto">
            <div class="time">
              <van-cell
                style="padding: 0"
                is-link
                @click="staffListClick(details)"
              >
                <template #title>
                  <span class="custom-title time" style="font-size: 16px"
                    >从业人数：{{ details.StaffNum }}</span
                  >
                </template>
                <!-- <template #right-icon>
                  <van-button size="small"
                    color="#ffd225"
                    type="primary"
                    style="border-radius:5px;width: 22%;"
                    @click="staffListClick(details)">从业人员</van-button>

                </template> -->
              </van-cell>
            </div>
          </div>
        </div>
        <div>
          <van-divider />
          <div class="time" style="width: 95%; margin: auto">
            <van-cell
              style="padding: 0"
              is-link
              @click="guestAllClick(details)"
            >
              <template #title>
                <span class="custom-title time" style="font-size: 16px"
                  >历史旅客</span
                >
              </template>
            </van-cell>
          </div>
        </div>
        <div>
          <van-divider />
          <div class="time" style="width: 95%; margin: auto">
            <van-cell
              style="padding: 0"
              is-link
              @click="hotelOrderClick(details)"
            >
              <template #title>
                <span class="custom-title time" style="font-size: 16px"
                  >订单管理</span
                >
              </template>
            </van-cell>
          </div>
        </div>
        <div>
          <van-divider />
          <div class="time" style="width: 95%; margin: auto">
            <van-cell style="padding: 0" :border="false">
              <template #title>
                <span class="custom-title time" style="font-size: 16px"
                  >房间管理</span
                >
              </template>
            </van-cell>
            <div style="display: flex; justify-content: space-around">
              <van-button
                class="button"
                @click="roomTypeClick(details)"
                style="width: 35%; border-radius: 5px"
                >房间类型</van-button
              >
              <van-button
                class="button"
                @click="roomClick(details)"
                style="width: 35%; border-radius: 5px"
                >房间维护</van-button
              >
            </div>
          </div>
          <!-- <van-cell style="padding:0;width:90%;margin:auto;"
            is-link>
            <template #title>
              <span style="font-size:16px;">房间类型</span>
            </template>
          </van-cell> -->
        </div>
        <div
          class="essay"
          v-if="details.Introduce != null && details.Introduce != ''"
          style="width: 100%"
        >
          <div style="margin-bottom: 20px">
            <div class="zhuyao">—&nbsp;&nbsp;民宿详情&nbsp;&nbsp;—</div>
          </div>
          <div class="essayContent" v-html="details.Introduce"></div>
        </div>
      </div>
    </div>
    <div class="botton">
      <div style="width: 100%; text-align: center">
        <!-- <van-button class="button"
          @click="guestAllClick(details)"
          style="width:30%;margin-right: 10px;border-radius: 5px;"
          type="info">历史旅客</van-button> -->
        <van-button
          class="button"
          @click="guestClick(details)"
          style="width: 90%; border-radius: 5px"
          color="#11e67b"
          type="info"
          >旅客登记</van-button
        >
        <!-- <van-button class="button"
          @click="roomClick(details)"
          style="width:30%;border-radius: 5px;"
          type="warning">房间类型</van-button>
        <van-button class="button"
          @click="roomClick(details)"
          style="width:30%;border-radius: 5px;"
          type="warning">房间维护</van-button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { HGetMyHotel, WxEnrolPromotion } from "@/api/RealInfo";
import config from "@/config";
import Vue from "vue";
import { Toast } from "vant";
import { Dialog } from "vant";
import { setOpenId, getOpenId } from "@/utils/auth";
Vue.use(Toast);
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      details: {},
      count: "",
      imgsList: [],
      list: [],
      strategy: {},
      landScape: [],
      url: config.hostUrl,
    };
  },
  created() {
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    // console.log(this.$route.params);
    this.wxGetPromotion();
  },
  methods: {
    //格式化日期
    filteryear(val) {
      // let gstime = val.activationDate.replace('T', ' ')
      if (val.StartDate != null && val.StartDate != "") {
        let gstime = val.StartDate.substring(0, 10);
        return gstime;
      }
    },
    onChange(index) {
      this.count = index;
    },
    wxGetPromotion() {
      HGetMyHotel({
        openID: getOpenId(),
        hotelId: this.$route.params.hotelId,
      }).then((res) => {
        this.details = res.data.data;
        if (this.details.Imgs != null && this.details.Imgs != "") {
          // var ImgsList = this.list.Imgs.split(',')
          // this.details.Imgs = ImgsList
        } else {
          this.details.Imgs =
            "http://alycommunity.anluoyun.cn/lc-lcx/2023/6/35e14c95-10b.png";
        }
        // this.details.StartDate = res.data.data.StartDate.substring(0, 10);
        // this.details.EndDate = res.data.data.EndDate.substring(0, 10);
      });
      // console.log(this.details);
    },
    // 编辑民宿
    addHotel(val) {
      var url = "/hotels/myHotelsList/addHotel/1/" + val.HotelId;
      this.$router.push(url);
    },
    // 设置管理员
    managerClick(val) {
      var url = "/hotels/myHotelsList/managerHotel/" + val.HotelId;
      this.$router.push(url);
    },
    // 从业人员
    staffListClick(val) {
      var url = "/hotels/myHotelsList/myStaffList/" + val.HotelId;
      this.$router.push(url);
    },
    // 旅客列表
    guestClick(val) {
      var url = "/hotels/myHotelsList/myGuestList/" + val.HotelId;
      this.$router.push(url);
    },
    // 房间类型
    roomTypeClick(val) {
      var url = "/hotels/myHotelsXq/roomType/" + val.HotelId;
      this.$router.push(url);
    },
    // 房间列表
    roomClick(val) {
      var url = "/hotels/myHotelsList/roomList/" + val.HotelId;
      this.$router.push(url);
    },
    // 历史旅客
    guestAllClick(val) {
      var url = "/hotels/myHotelsList/myGuestListAll/" + val.HotelId;
      this.$router.push(url);
    },
    // 订单管理
    hotelOrderClick(val) {
      var url = "/hotels/myHotelsXq/hotelOrder/" + val.HotelId;
      this.$router.push(url);
    },
  },
};
</script>

<style scoped>
.custom-indicator {
  position: absolute;
  right: 15px;
  bottom: 15px;
  padding: 2px 5px;
  font-size: 14px;
  color: #ffffff;
  /* background: rgba(255, 255, 255, 0.788); */
}
.biaoti {
  display: flex;
  justify-content: space-between;
}
.title {
  color: #0c0c0c;
  font-size: 24px;
  font-weight: 550;
}
.money {
  line-height: 33px;
  font-family: PingFang SC;
  font-weight: 550;
  color: #fe5b47;
  font-size: 18px;
}
.time {
  /* display: flex;
  justify-content: space-between; */
  padding: 5px 0;
  color: #0c0c0c;
  font-weight: bold;
  line-height: 30px;
}
.zhuyao {
  font-size: 1.17em;
  font-weight: bold;
  margin-top: 1em;
  text-align: center;
}
.zhuyaoJd {
  font-size: 1.17em;
  font-weight: bold;
  margin-top: 1em;
  text-align: left;
  display: flex;
  justify-content: space-between;
}
.zhuyaoJd span {
  color: rgb(153, 153, 153);
  float: right;
  font-size: 12pt;
  font-weight: 300;
}
.waterfall {
  /* width: 95%; */
  margin: 10px auto;
  column-count: 2;
  column-gap: 0;
  column-width: auto;
}
.waterfall .list {
  margin: 0px 7px 10px 7px;
  break-inside: avoid;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 5px 17px 1px rgba(0, 0, 0, 0.07);
}
.list img {
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  /* height: 160px; */
  /* border-radius: 0px; */
}
.botton {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
  padding: 13px 0;
}
.address {
  margin: 10px 0;
  color: #92600d;
  font-size: 16px;
}
.shopType {
  background: #d9841a;
  color: #fff;
  padding: 3px 6px;
  border-radius: 8px;
  font-size: 12px;
  margin-left: 6px;
}
.mapImg {
  background-image: url("http://xczxapi.zhsq.cloud//upload/images/2022/6/1ee98423-7b1.png");
  background-size: 100%;
  background-repeat: no-repeat;
}
.addressImage {
  width: 20px;
  height: 25px;
  margin-top: 8px;
}
</style>